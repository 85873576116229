// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".header_header__hC3A- {\n    display: flex;\n    align-items: center;\n    background: white;\n    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);\n    padding: 12px 24px;\n    border-bottom: 1px solid #ECECEC;\n}\n\n.header_logo__2OXNl {\n    display: flex;\n    align-items: center;\n    grid-gap: 8px;\n    gap: 8px;\n    text-decoration: none;\n    color: inherit;\n}\n\n.header_Logo__1zqZf {\n    width: 167px;\n}", "",{"version":3,"sources":["webpack://src/header/header.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,iBAAiB;IACjB,wCAAwC;IACxC,kBAAkB;IAClB,gCAAgC;AACpC;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,aAAQ;IAAR,QAAQ;IACR,qBAAqB;IACrB,cAAc;AAClB;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".header {\n    display: flex;\n    align-items: center;\n    background: white;\n    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);\n    padding: 12px 24px;\n    border-bottom: 1px solid #ECECEC;\n}\n\n.logo {\n    display: flex;\n    align-items: center;\n    gap: 8px;\n    text-decoration: none;\n    color: inherit;\n}\n\n.Logo {\n    width: 167px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "header_header__hC3A-",
	"logo": "header_logo__2OXNl",
	"Logo": "header_Logo__1zqZf"
};
export default ___CSS_LOADER_EXPORT___;

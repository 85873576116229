import React, { useState, useEffect } from 'react';
import VideoCall from "./VideoCall";
import Chat from "./Chat";
import '../src/App.css'
import Header from './header/Header';
import CustomerPanel from './panel/CustomerPanel';
import GoogleMap from './map/GoogleMap';

function App() {
  const [inCall, setInCall] = useState(true);
  const [userDetails, setUserDetails] = useState(null);
  const [error, setError] = useState(null);



  const clearSearchParams = () => {
    const url = new URL(window.location.href);
    url.search = '';
    window.history.replaceState({}, document.title, url.toString());
  };

  const handleDisconnect = (message) => {
    setError(message || "Remote user disconnected");
    setInCall(false);
    clearSearchParams();
  };

  const getAlarmIdFromQuery = () => {
    const params = new URLSearchParams(window.location.search);
    return params.get("alarmId");
  };

  useEffect(() => {

    if (!window.location.search) {
      handleDisconnect("Session Expired: No parameters found in the URL.");
      return;
    }

    const alarmId = getAlarmIdFromQuery();
    if (alarmId) {
      fetchUserDetails(alarmId);
    } else {
      handleDisconnect("Alarm ID not found in query parameters");
    }
  }, []);

  const fetchUserDetails = async (alarmId) => {
    try {
      const response = await fetch(
        `https://stagdashboard.iylus.com/api/v1/user/getUserForDashboard?alarmId=${alarmId}`
      );
      if (!response.ok) {
        throw new Error(`API Error: ${response.status}`);
      }
      const data = await response.json();
      const newdata = data.data;
      console.log("???????????????????????????????????>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>", newdata);

      setUserDetails(newdata);

    } catch (err) {
      handleDisconnect(err.message);
    }
  };

  if (error) {
    return (
      <div className="session-expired">
        <h1>{error}</h1>
      </div>
    );
  }

  return (
    <div className="App">
      <Header />
      <div className="customer-panel-container">
        <CustomerPanel userDetails={userDetails} />
      </div>
      <div className="main-content">
        <div className="right-section">
          <GoogleMap userDetails={userDetails} />
        </div>
        <div className="left-section">
          <div className="video-container">
            <VideoCall setInCall={setInCall} userDetails={userDetails} onUserDisconnect={handleDisconnect} />
          </div>
          <div className="chat-container">
            <Chat userDetails={userDetails} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;

import React, { useState, useRef } from 'react';
import '../CustomerPanel.css';

const CustomerPanel = ({ userDetails }) => {
    // If no customer info is provided, use default data
    const defaultCustomer = {
        name: 'Hania Kazmi',
        phone: '0300-1234567',
        location: 'Pearl Hight, Business Square, Gulberg Islamabad'
    };

    const customer = userDetails || defaultCustomer;


    const [recording, setRecording] = useState(false);
    const mediaRecorderRef = useRef(null);
    const [stream, setStream] = useState(null);

    const startRecording = async () => {
        const displayMediaOptions = {
            video: true,
            audio: {
                echoCancellation: true,
                noiseSuppression: true,
                sampleRate: 44100,
            }
        };

        try {
            const screenStream = await navigator.mediaDevices.getDisplayMedia({
                video: true,
                audio: {
                    echoCancellation: true,
                    noiseSuppression: true,
                    sampleRate: 44100,
                },
                audio: true, // Request system audio capture
            });

            // Combining system audio with microphone audio
            const audioStream = await navigator.mediaDevices.getUserMedia({ audio: true });
            const combinedStream = new MediaStream([
                ...screenStream.getVideoTracks(),
                ...screenStream.getAudioTracks(), // Adding system audio
                ...audioStream.getAudioTracks() // Adding microphone audio
            ]);

            setStream(combinedStream);
            mediaRecorderRef.current = new MediaRecorder(combinedStream);

            mediaRecorderRef.current.ondataavailable = (event) => {
                const blob = new Blob([event.data], { type: 'video/webm' });
                const url = URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.download = 'iyzilUserRecording.webm';
                document.body.appendChild(a);
                a.click();
                URL.revokeObjectURL(url);
            };

            mediaRecorderRef.current.start();
            setRecording(true);
        } catch (err) {
            if (err.name === 'NotAllowedError') {
                alert('Screen capture permission was denied. Please allow screen capture.');
            } else {
                console.error("Error: " + err);
            }
        }
    };

    const stopRecording = () => {
        mediaRecorderRef.current.stop();
        stream.getTracks().forEach(track => track.stop());
        setRecording(false);
    };

    return (
        <div className="customer-panel">
            <div className="customer-info">
                <div className="info-item">
                    <div className="info-label">Name</div>
                    <div className="info-value">{customer.first_name && customer.last_name ? `${customer.first_name} ${customer.last_name}` : defaultCustomer.name}</div>
                </div>
                <div className="info-item">
                    <div className="info-label">Phone#</div>
                    <div className="info-value">{customer.contact || defaultCustomer.phone}</div>
                </div>
                <div className="info-item">
                    <div className="info-label">Location</div>
                    <div className="info-value">{customer.UserAddress || defaultCustomer.location}</div>
                </div>


                {/* <div className="screen-recorder mt-auto d-flex justify-content-end" style={{ marginLeft: "auto" }}>
                    <button
                        className="screenRecorder"
                        onClick={recording ? stopRecording : startRecording}
                    >
                        {recording ? 'Stop Recording' : 'Start Recording'}
                    </button>
                </div> */}
            </div>

        </div>


    );
};

export default CustomerPanel;


import { useEffect, useState } from "react";
import { useClient } from "./settings";
import 'bootstrap/dist/css/bootstrap.min.css';

export default function Controls(props) {
  const client = useClient();
  const { tracks, setStart, setInCall } = props;
  const [trackState, setTrackState] = useState({ video: true, audio: false });
  const [inChannel, setInChannel] = useState(true);

  const mute = async (type) => {
    if (type === "audio") {
      await tracks[0].setEnabled(!trackState.audio);
      setTrackState((prevState) => ({ ...prevState, audio: !prevState.audio }));
    } else if (type === "video") {
      await tracks[1].setEnabled(!trackState.video);
      setTrackState((prevState) => ({ ...prevState, video: !prevState.video }));
    }
  };

  const leaveChannel = async () => {
    await client.leave();
    client.removeAllListeners();
    tracks[0].close();
    tracks[1].close();
    setStart(false);
    setInCall(false);
    setInChannel(false);
  };

  return (
    <div className="row justify-content-center" style={{ padding: "15px", position:"absolute", top:"86%", left:"29%" }}>
      {inChannel && (
        <>
{/*          <div className="col-auto">
            <button
              className={`btn ${trackState.audio ? "btn-primary" : "btn-danger"}`}
              onClick={() => mute("audio")}
            >
              {trackState.audio ? "Mic On" : "Mic Off"}
            </button>
          </div>*/}
{/*          <div className="col-auto">
            <button
              className={`btn ${trackState.video ? "btn-primary" : "btn-danger"}`}
              onClick={() => mute("video")}
            >
              {trackState.video ? "Camera Off" : "Camera On"}
            </button>
          </div>*/}
{/*          <div className="col-auto">
            <button className="btn btn-secondary" onClick={leaveChannel}>
              Leave
            </button>
          </div>*/}
        </>
      )}
    </div>
  );
}

import React, { useState, useEffect, useRef } from 'react';
import { database } from './firebase';
import { ref, onValue, push, set } from "firebase/database";
import './chat.css';
import ChatIcon from './assets/images/chat.svg';
import userDummyIcon from './assets/images/dummy.svg';

const Chat = ({ userDetails }) => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const currentUser = "Agent";
  const messagesEndRef = useRef(null);
  const prevMessagesRef = useRef([]);
  const contactNumber = userDetails?.contact;
  const messagesRef = ref(database, `/groups/${contactNumber}`);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    const unsubscribe = onValue(messagesRef, (snapshot) => {
      const data = snapshot.val();
      const messagesList = data ? Object.keys(data).map(key => ({ id: key, ...data[key] })) : [];
      setMessages(messagesList);
    });

    return () => unsubscribe();
  }, [messagesRef]);

  useEffect(() => {
    if (prevMessagesRef.current.length !== messages.length) {
      scrollToBottom();
    }
    prevMessagesRef.current = messages;
  }, [messages]);

  const handleSendMessage = async () => {
    if (newMessage.trim() === '') return;

    const newMessageRef = push(messagesRef);
    try {
      await set(newMessageRef, {
        message: newMessage,
        name: currentUser,
        timestamp: Date.now()
      });
      setNewMessage('');
    } catch (error) {
      console.error("Error writing new message to Firebase Database", error);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSendMessage();
    }
  }


  const formatTime = (timestamp) => {
    const date = new Date(timestamp);
    if (isNaN(date.getTime())) {
      return;
    }
    return date.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    });
  };

  return (
    <div className="chat-wrapper">
      <div className="messages-container">
        {
          messages.map((message) => (
            <div
              key={message.id}
              className={`message-row ${message.name === currentUser ? "sent" : "received"}`}
            >
              <img
                alt={message.name}
                src={userDummyIcon}
              />
              <div className='message-div'>
                <div className={`message-bubble ${message.name === currentUser ? "sent" : "received"}`}>
                  <p className="message-text"><strong>{message.name}:</strong> {message.message}</p>
                </div>
                <span className="message-time">{formatTime(message.timestamp)}</span>
              </div>
            </div>
          ))}
        <div ref={messagesEndRef} />
      </div>
      {/* <div className="messages-container">
        {messages.map((message) => (
          <div
            key={message.id}
            className={`message-row ${message.name === currentUser ? "sent" : "received"}`}
          >
            <div className="message-content">
              <div className="message-header">
                <span className="message-sender">{message.name}</span>
              </div>
              <div className={`message-bubble ${message.name === currentUser ? "sent" : "received"}`}>
                <p className="message-text">{message.message}</p>
                <span className="message-time">{formatTime(message.timestamp)}</span>
              </div>
            </div>
            <img
              alt={message.name}
              src={userDummyIcon}
            />
          </div>
        ))}
        <div ref={messagesEndRef} />
      </div> */}
      <div className="input-container">
        <input
          type="text"
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          onKeyPress={handleKeyPress}
          className="message-input"
          placeholder="Send message"
        />
        <img
          onClick={handleSendMessage}
          src={ChatIcon}
          alt="Send"
          className="send-icon"
        />
      </div>
    </div>
  );
};

export default Chat;


import { useState, useEffect } from "react";
import {
  config,
  useClient,
  useMicrophoneAndCameraTracks,
} from "./settings.js";
import Video from "./Video";
import Controls from "./Controls";
import "bootstrap/dist/css/bootstrap.min.css";

export default function VideoCall(props) {
  const { setInCall, userDetails } = props;
  const [users, setUsers] = useState([]);
  const [start, setStart] = useState(false);
  const [apiToken, setApiToken] = useState(null);
  const client = useClient();
  const { ready, tracks } = useMicrophoneAndCameraTracks();
  // Generate a unique channel name or use a fixed one
  // const channelName = window.location.pathname.substring(1) || "+923006373686";

  // const getChannelNameFromParams = () => {
  //   const params = new URLSearchParams(window.location.search);
  //   return params.get("contact") || "+923006373686"; 
  // }

  const channelNameNew = userDetails?.contact;

  const channelName = channelNameNew;

  console.log(channelName, "______________________________________________________________________");

  useEffect(() => {
    // Mute audio after tracks are published
    if (start && tracks) {
      tracks[0].setEnabled(false);
    }
  }, [start, tracks]);

  useEffect(() => {
    let init = async () => {
      if (!channelName) {
        console.error("Invalid channel name");
        return;
      }

      client.on("user-published", async (user, mediaType) => {
        await client.subscribe(user, mediaType);
        if (mediaType === "video") {
          setUsers((prevUsers) => [...prevUsers, user]);
        }
        if (mediaType === "audio") {
          user.audioTrack.play();
        }
      });

      client.on("user-unpublished", (user, mediaType) => {
        if (mediaType === "audio") {
          if (user.audioTrack) user.audioTrack.stop();
        }
        if (mediaType === "video") {
          setUsers((prevUsers) =>
            prevUsers.filter((User) => User.uid !== user.uid)
          );
        }
      });

      client.on("user-left", (user) => {
        setUsers((prevUsers) =>
          prevUsers.filter((User) => User.uid !== user.uid)
        ); props.onUserDisconnect("Remote user disconnected.");
      });

      try {

        let tok = ""
        const uid = 12345;
        const fetchToken = async () => {
          const encodedChannelName = encodeURIComponent(channelName);
          console.log("___________________>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>", encodedChannelName);
          const apiUrl = `https://stagdashboard.iylus.com/api/v1/agora/Token?channelName=${encodedChannelName}&uid=${uid}`;
          console.log("apiUrl ", apiUrl)
          const username = "1173bc97d520431da9b75428e279f407";
          const password = "5c98d30e4193491397b97544ff77cbab";

          try {
            const response = await fetch(apiUrl, {
              method: "GET",
              headers: {
                Authorization: `Basic ${btoa(`${username}:${password}`)}`,
                "Content-Type": "application/json",
              },
            });

            if (response) {
              const data = await response.json();
              tok = data.token;
              setApiToken(data.token);
            } else {
              console.error("Failed to fetch token:", response.statusText);
            }
          } catch (error) {
            console.error("Error fetching token:", error);
          }
        }

        await fetchToken();
        console.log("11111111111111111111111111111133333333333333333333333333333333", tok, channelName, JSON.stringify(config));
        // Use the extracted channel name
        await client.join("eb6e2f52049b4f5b9c72fbb7f5241af8", channelName, tok, uid);
        // console.log("1- -11111111111111111111111111111111111111111111111", channelName, config.appId, apiToken);

        // Publish tracks after successfully joining
        if (tracks) {
          await client.publish([tracks[0], tracks[1]]);
          setStart(true);
        }
      } catch (error) {
        console.error("Error joining channel:", error);
      }
    };

    // Only initialize if client, tracks are ready
    if (ready && tracks) {
      init();
    }
  }, [channelName, client, ready, tracks]);

  return (
    <div className="d-flex flex-column">
      {/* Controls */}

      {/* Video Section */}
      <div className="flex-grow-1" style={{ height: "95%" }}>
        {start && tracks && <Video tracks={tracks} users={users} />}
      </div>


      <div className="flex-shrink-0">
        {ready && tracks && (
          <Controls
            tracks={tracks}
            setStart={setStart}
            setInCall={setInCall}
          />
        )}
      </div>
    </div>
  );
}
import { createClient, createMicrophoneAndCameraTracks } from "agora-rtc-react";
const appId = "eb6e2f52049b4f5b9c72fbb7f5241af8";
const appCert = "ee804a6b479f46cfa65bd83a0597ca53";

const token = null;
    
export const config = { mode: "rtc", codec: "vp8", appId: appId, appCert: appCert, token: token };
export const useClient = createClient(config);
export const useMicrophoneAndCameraTracks = createMicrophoneAndCameraTracks();
export const channelName = window.$contactNumber;

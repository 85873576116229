import { AgoraVideoPlayer } from "agora-rtc-react";
import { useState, useEffect } from "react";
import './video.css';

export default function Video(props) {
  const { users, tracks } = props;


  // console.log("************************user tracksssssssssssssssssssssssssssss*******************", users, tracks);


  const [gridSpacing, setGridSpacing] = useState(12);

  useEffect(() => {
    if (users.length > 1) {
      setGridSpacing(Math.max(Math.floor(12 / users.length), 4));
    } else {
      setGridSpacing(12);
    }
  }, [users]);




  return (
    <div className="video-grid">
      {/* {tracks && tracks[1] && (
        <div className="video-container local" key="local-user">
          <AgoraVideoPlayer
            videoTrack={tracks[1]}
            className="agora-video"
          />
        </div>
      )} */}

      {users.length > 0 &&
        users.map((user, index) => {
          console.log("************************user*******************", user)
          if (user.videoTrack) {
            return (
              <div
                className={`video-container ${index === 0 && users.length === 1 ? "full" : ""}`}
                key={user.uid}
              >
                <AgoraVideoPlayer
                  videoTrack={user.videoTrack}
                  className="agora-video"
                />
              </div>
            );
          } else return null;
        })}
    </div>
  );
}


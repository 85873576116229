import React from 'react';

//Styles
import Styles from "./header.module.css";

//assets
import Logo from "../assets/images/logo.svg";

const Header = () => {
    return (
        <header className={Styles.header}>
            <a href="/" className={Styles.logo}>
                <img src={Logo} alt="EagleEye AI Logo" className={Styles.Logo} />
            </a>
        </header>
    )
}

export default Header;
import React, { useEffect, useRef, useState } from 'react';
import socketIOClient from 'socket.io-client';
import { SOCKET_ENDPOINT } from '../config/keys.config';
import { SOCKET_AUTHENTICATION , SOCKET_LIVELOCATION } from '../constants/socket';
import constants from '../constants/socket';

const GoogleMap = ({ userDetails }) => {
    const mapRef = useRef(null);
    const markerRef = useRef(null);
    const mapInstanceRef = useRef(null);
    const socketRef = useRef(null);
    const [socketData, setSocketData] = useState(null);
    console.log('socketData', socketData)

    // Initialize socket connection
    useEffect(() => {
        console.log('Connecting to socket at:', SOCKET_ENDPOINT);
        
        socketRef.current = socketIOClient(SOCKET_ENDPOINT, {
            path: '/api/socket.io',
            reconnectionAttempts: 5,
            transports: ['websocket', 'polling'] // Explicitly specify transport methods
        });

        socketRef.current.on('connect', () => {
            console.log('Socket connected successfully');
            
            socketRef.current.emit(SOCKET_AUTHENTICATION, {
                token: constants.socketToken
            });
            console.log('Authentication event emitted');
        });

        
        socketRef.current.on('connect_error', (error) => {
            console.error('Socket connection error:', error);
        });
        
        return () => {
            console.log('Cleaning up socket connection');
            if (socketRef.current) {
                socketRef.current.disconnect();
            }
        };
    }, [socketData]);

   
 useEffect(()=>{
    socketRef.current.on(SOCKET_LIVELOCATION, (data) => {
        console.log('Received live location data:', data);
        setSocketData(data);
    });
 },[socketData])

   
    useEffect(() => {
        if (!mapRef.current) return;

        const defaultCoordinates = { lat: 33.6844, lng: 73.0479 };
        const initialCoordinates = userDetails?.iyzilDetail?.coordinates
            ? {
                lat: parseFloat(userDetails.iyzilDetail.coordinates.lat),
                lng: parseFloat(userDetails.iyzilDetail.coordinates.lng),
            }
            : defaultCoordinates;

        // Initialize map only if it hasn't been initialized
        if (!mapInstanceRef.current) {
            console.log('Initializing map with coordinates:', initialCoordinates);
            mapInstanceRef.current = new window.google.maps.Map(mapRef.current, {
                center: initialCoordinates,
                zoom: 12,
            });

            markerRef.current = new window.google.maps.Marker({
                position: initialCoordinates,
                map: mapInstanceRef.current,
                title: 'User Location',
            });
        }

        // Update marker position if on basis socket data
        if (socketData) {
            const { latitude, longitude } = socketData;
            if (latitude && longitude) {
                console.log('Updating marker position to:', latitude, longitude);
                const updatedPosition = {
                    lat: parseFloat(latitude),
                    lng: parseFloat(longitude)
                };
                
                if (markerRef.current && mapInstanceRef.current) {
                    markerRef.current.setPosition(updatedPosition);
                    mapInstanceRef.current.panTo(updatedPosition);
                    console.log('Marker and map updated successfully');
                }
            }
        }
    }, [userDetails, socketData]);

    return (
        <div
            ref={mapRef}
            style={{
                width: '100%',
                height: '100%',
                border: '1px solid #ececec',
            }}
        />
    );
};

export default GoogleMap;

